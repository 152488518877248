.titulo {
  text-align: left;
  font: "Arial";
  font-size: "30px";
  font-weight: bold;
}
.contenedor-bo{
  width: 100%;
  display: flex;
  justify-content: space-between; 
  background-color: #f3f3f3;
}


[data-amplify-authenticator][data-variation="modal"] {
  background-color: #2971b4 !important;
}

#pernexiumApp .amplify-tabs__item--active {
  color: #2e77bb;
  border-color: #2e77bb;
}

#pernexiumApp .amplify-tabs__item--active:hover {
  color: #042747;
  border-color: #042747;
}

#pernexiumApp .amplify-button--primary {
  background-color: #2e77bb;
  border-color: #2e77bb;
}

#pernexiumApp .amplify-button--primary:hover {
  background-color: #042747;
  border-color: #042747;
}

.etiqueta {
  background-color: #fefefe;
  height: 75px;
  width: 323px;
  border-radius: 15px;
  border: 1px solid #c0c0c0;
  cursor: pointer;
}

.textonombre {
  font: "Arial";
  font-size: 20px;
  font-weight: bold;
  position: relative;
  left: 50px;
}

.status {
  font-size: 15px;
  position: relative;
  left: 70px;
}

.foto-usuario {
  height: 52px;
  width: 51px;
  border: 100px;
  position:absolute;
  margin-top: -25px;
  margin-left: 5px;
}

.boton-general {
  width: 145px;
  height: 28px;
  border: none;
  color: #636363;
  background-color: #f4f4f4;
  cursor: pointer;
  font-weight: bold;
}

.boton-general-seleccionado {
  width: 145px;
  height: 28px;
  border: none;
  background-color: #e0f0ff;
  cursor: pointer;
  color: #2e77bb;
  border-color: #6aa8e1;
  font-weight: bold;
}

.texto-general {
  font-size: 13.31px;
  color: #636363;
}

.subtitulo {
  font-size: 15px;
  color: #636363;
}

.boton-contexto {
  width: 152px;
  height: 28px;
  border: none;
  color: #636363;
  background-color: #f4f4f4;
  cursor: pointer;
  font-weight: bold;
}

.boton-contexto-seleccionado {
  width: 145px;
  height: 28px;
  border: none;
  background-color: #e0f0ff;
  cursor: pointer;
  color: #2e77bb;
  border-color: #6aa8e1;
  font-weight: bold;
}

.boton-conocimiento {
  width: 236px;
  height: 28px;
  border: none;
  color: #636363;
  background-color: #f4f4f4;
  cursor: pointer;
  font-weight: bold;
}

.boton-conocimiento-seleccionado {
  width: 236px;
  height: 28px;
  border: none;
  background-color: #e0f0ff;
  cursor: pointer;
  color: #2e77bb;
  border-color: #6aa8e1;
  font-weight: bold;
}

.boton-medio {
  width: 236px;
  height: 28px;
  border: none;
  color: #636363;
  background-color: #f4f4f4;
  cursor: pointer;
  font-weight: bold;
}

.boton-medio-seleccionado {
  width: 236px;
  height: 28px;
  border: none;
  background-color: #e0f0ff;
  cursor: pointer;
  color: #2e77bb;
  border-color: #6aa8e1;
  font-weight: bold;
}

.boton-integraciones {
  width: 200px;
  height: 28px;
  border: none;
  color: #636363;
  background-color: #f4f4f4;
  cursor: pointer;
  font-weight: bold;
}

.boton-integraciones-seleccionado {
  width: 200px;
  height: 28px;
  border: none;
  background-color: #e0f0ff;
  cursor: pointer;
  color: #2e77bb;
  border-color: #6aa8e1;
  font-weight: bold;
}

.boton-pruebas {
  width: 100px;
  height: 28px;
  border: none;
  color: #636363;
  background-color: #f4f4f4;
  cursor: pointer;
  font-weight: bold;
}

.boton-pruebas-seleccionado {
  width: 100px;
  height: 28px;
  border: none;
  background-color: #e0f0ff;
  cursor: pointer;
  color: #2e77bb;
  border-color: #6aa8e1;
  font-weight: bold;
}

.elemento-ventana {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  height: 100vh;
  width: 100%;
}

.boton-regresar {
  cursor: pointer;
  background-color: white;
  color: #636363;
  border: none;
  pointer-events: auto;
  top: 20px;
}

.ventana-menu {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.archivo-2 {
  border-radius: 23.15px;
  border: none;
  background-color: #4177b6;
  color: white;
  width: 124.3px;
  height: 40.15px;
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
}

.archivo {
  border-radius: 23.15px;
  border: none;
  background-color: #4177b6;
  color: white;
  width: 124.3px;
  height: 40.15px;
  cursor: pointer;
  font-weight: bold;
}

.cancelar {
  border-radius: 23.15px;
  border: none;
  background-color: #c9c9c9;
  color: #565656;
  width: 124.3px;
  height: 40.15px;
  cursor: pointer;
  font-weight: bold;
}

.input {
  width: 448px;
  height: 42px;
  background-color: #f5f5f5;
  border-radius: 10px;
  border: none;
  font-size: 13px;
  color: #636363;
}

.imagen-boton-regresar {
  width: 13px;
  height: 13px;
}

.div-boton-regresar {
  text-align: right;
  margin-right: 20px;
  margin-top: 20px;
}

.texto-nombre-menu {
  font-size: 30px;
  font-weight: bold;
}

.info-contacto {
  text-align: left;
  align-items: left;
  width: 281px;
  background-color: gray;
}

.info-contacto-div {
  width: 160px;
  height: 112px;
  border: 1px solid #a0a0a0;
  background-color: white;
  border-radius: 20px;
  margin-right: 20px;
  margin-left: 20px;
}

.info-contacto-info {
  font-size: 15px;
  font-weight: bold;
}

.info-contacto-texto {
  font-size: 15px;
}

.info-contacto-boton-resumen {
  font-size: 10px;
  width: 179px;
  height: 30px;
  color: #1d8ece;
  background-color: #e5f2f6;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

.info-contacto-boton-etiqueta {
  font-size: 10px;
  width: 77px;
  height: 30px;
  color: #27852c;
  background-color: #c9e9d4;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

.info-contacto-nombre {
  margin-top: 2px;
  font-size: 15px;
  padding: 10px;
}

.info-contacto-numero {
  font-size: 13px;
  padding: 10px;
}

.info-contacto-fecha {
  margin-top: -2px;
  font-size: 7px;
}

.foto-usuario-chatbot {
  height: 96px;
  width: 96px;
  border: 100px;
  padding: 10px;
}

.foto-usuario-contacto {
  height: 39px;
  width: 39px;
  border: 100px;
}

.imagen-message {
  width: 18px;
  height: 18px;
}

.fondo-imagen-message {
  background-color: #d0e2f3;
  border: none;
  border-radius: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.info-contacto-mensaje {
  font-size: 10px;
  margin-bottom: -7px;
}

.info-contacto-detalles {
  text-align: left;
  align-items: left;
}

.info-contacto-div-grande {
  height: 100vh;
  width: 20vw;
  border: 1px solid #a0a0a0;
  background-color: #f4f4f4;
  position: relative;
  left: 33px;
}

.message-contenedor {
  width: 20vw;
  height: 10vh;
  border-bottom: 1px solid #a0a0a0;
  cursor: pointer;
  position:relative;
  top: 140px;
}

.message-WHATSAPP {
  font-size: 10px;
  color: #1fa727;
  padding-left: 10px;
  font-weight: bold;
}

.message-nombre {
  font-size: 16px;
  padding-left: 10px;
  font-weight: bold;
  width: 14vw;
}

.message-fecha {
  font-size: 12px;
  color: #0064c2;
  position: relative;
  right: 10px;
}

.message-promocion {
  font-size: 14px;
  color: #636363;
  padding-left: 10px;
  width: 16.5vw;
}

.message-listopagar {
  width: 103px;
  height: 17px;
  margin-bottom: 40p;
}

.message-letrapagar {
  background-color: #e5f2f6;
  border-radius: 20px;
  color: #1d8ece;
  font-size: 9px;
  padding-left: 10px;
}

.message-notificacion {
  margin-bottom: 25px;
}

.dashboard-boton {
  display: flex;
  align-items: center;
  width: 14.52vw;
  height: 6.25vh;
  font-weight: bold;
  color: #3e3e3e;
  background-color: white;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  margin-bottom: 12px;
}

.dashboard-boton-delgado {
  display: flex;
  align-items: center;
  width: 3.38vw;
  height: 6.25vh;
  font-weight: bold;
  color: #3e3e3e;
  background-color: white;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  margin-bottom: 12px;
}

.dashboard-boton-seleccionado {
  display: flex;
  align-items: center;
  width: 14.52vw;
  height: 6.25vh;
  font-weight: bold;
  color: #2e77bb;
  background-color: #e8f9ff;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  margin-bottom: 12px;
  text-align: left;
  line-height: 48px;
}

.dashboard-boton-seleccionado-delgado {
  display: flex;
  align-items: center;
  width: 3.38vw;
  height: 6.25vh;
  font-weight: bold;
  color: #2e77bb;
  background-color: #e8f9ff;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  margin-bottom: 12px;
  text-align: left;
  line-height: 48px;
}

.dashboard-boton-settings {
  display: flex;
  align-items: center;
  width: 14.52vw;
  height: 6.25vh;
  font-weight: bold;
  color: #3e3e3e;
  background-color: white;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  text-align: left;
  line-height: 48px;
}

.dashboard-boton-settings-delgado {
  display: flex;
  align-items: center;
  width: 3.38vw;
  height: 6.25vh;
  font-weight: bold;
  color: #3e3e3e;
  background-color: white;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  text-align: left;
  line-height: 48px;
}

.dashboard-boton-settings-seleccionado {
  display: flex;
  align-items: center;
  width: 28.75vh;
  height: 6.25vh;
  font-weight: bold;
  color: #2e77bb;
  background-color: #e8f9ff;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  text-align: left;
  line-height: 48px;
}

.dashboard-boton-settings-seleccionado-delgado {
  display: flex;
  align-items: center;
  width: 3.38vw;
  height: 6.25vh;
  font-weight: bold;
  color: #2e77bb;
  background-color: #e8f9ff;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  text-align: left;
  line-height: 48px;
}

.dashboard-div-pernexium {
  width: 18.47vw;
  height: 100vh;
  background-color: #f4f4f4;
}

.dashboard-div-pernexium-delgado {
  width: 7.68vw;
  height: 100vh;
  background-color: #f4f4f4;
}

.dashboard-boton-logout {
  display: flex;
  align-items: center;
  width: 14.52vw;
  height: 6.25vh;
  font-weight: bold;
  color: #3e3e3e;
  background-color: white;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  text-align: left;
  line-height: 48px;
  position: absolute;
  bottom: 40px;
  left: auto;
}

.dashboard-boton-logout-delgado {
  display: flex;
  align-items: center;
  width: 3.38vw;
  height: 6.25vh;
  font-weight: bold;
  color: #3e3e3e;
  background-color: white;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  text-align: left;
  line-height: 48px;
  position: absolute;
  bottom: 40px;
  left: auto;
}

.dashboard-boton-seleccionado-logout {
  display: flex;
  align-items: center;
  width: 28.75vh;
  height: 6.25vh;
  font-weight: bold;
  color: #2e77bb;
  background-color: #e8f9ff;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  text-align: left;
  line-height: 48px;
  position: absolute;
  bottom: 40px;
  left: auto;
}

.dashboard-boton-seleccionado-logout-delgado {
  display: flex;
  align-items: center;
  width: 3.38vw;
  height: 6.25vh;
  font-weight: bold;
  color: #2e77bb;
  background-color: #e8f9ff;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  text-align: left;
  line-height: 48px;
  position: absolute;
  bottom: 40px;
  left: auto;
}

.dashboard-imagen-pernexium {
  margin-top: 72px;
  margin-bottom: 80px;
}

.dashboard-imagen-pernexium-delgado {
  margin-top: 72px;
  margin-bottom: 60px;
}

.messagebox {
  width: 300px;
  height: 19%;
  background-color: #f4f4f4;
  border-bottom: 1px solid #a0a0a0;
  position:fixed;
  left: 120px;
}

.messagebox-inbox {
  padding-left: 0px;
}

.botones-message-inbox {
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #f4f4f4;
}

.botones-message-filtro {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #f4f4f4;
}

.botones-message-inbox-img {
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.boton-search {
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #a0a0a0;
}

.message-inbox-text {
  font-size: 20px;
  font-weight: bold;
}

.message-conversacion {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.etiqueta-usuario {
  width: 117%;
  background-color: #f4f4f4;
  border: 1px solid #a0a0a0;
  border-top: none;
  border-bottom: none;
  height: 12.88vh;
  position: relative;
  left: -22px;
}

.input-nombre-org {
  width: 392px;
  height: 17px;
}

.input-descripcion-org {
  width: 392px;
  height: 86px;
  margin-bottom: 30px;
}

.input-nombre-general {
  width: 203px;
  height: 43px;
  border-radius: 6px;
  border: 1px solid #c9c9c9;
  margin-bottom: 20px;
}

.info-chatbot {
  margin-left: 40px;
  font-size: 20px;
  color: #2e77bb;
  font-weight: bold;
}

.texto-ID-General {
  font-size: 13.31px;
  color: #636363;
}

.div-usuario {
  width: 263px;
}

.usuario-nombre {
  font-size: 30px;
  font-weight: bold;
  margin-top: 50px;
  margin-left: 0px;
  position: relative;
}

.imagen-usuario {
  margin-top: 20px;
}

.usuario-ia {
  font-size: 20px;
}

.div-segundo {
  width: 203px;
  margin-top: -2px;
}

.segundo-nombre {
  font-size: 19px;
  font-weight: bold;
  margin-top: 20px;
}

.segundo-id {
  font-size: 19px;
  color: #636363;
}

.div-tercero {
  width: 477px;
  margin-top: 70px;
}

.tercero-id {
  margin-top: 20px;
  font-size: 17px;
  font-weight: bold;
  margin-top: 30px;
}

.tercero-id-texto {
  font-size: 13px;
  font-weight: bold;
  color: #636363;
  margin-top: 30px;
}

.input-nombre-chatbot {
  border-radius: 5.15px;
  background-color: white;
  color: black;
  width: 124.3px;
  height: 40.15px;
  cursor: text;
  font-weight: bold;
  left: 50px;
}

.subtitulo-conocimiento {
  font-size: 19px;
  font-weight: bold;
}

.titulo-conocimiento {
  font-size: 15px;
  font-weight: bold;
  color: #636363;
}

.titulo2-conocimiento {
  font-weight: bold;
  font-size: 20px;
}

.adjuntar-texto {
  font-size: 19px;
  font-weight: bold;
  color: black;
}

.div-adjuntar-archivo {
  width: 419px;
  height: 361px;
  border: 1px dotted #c9c9c9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 17px;
  color: #636363;
}

.contexto-subtitulo {
  font-size: 19px;
  font-weight: bold;
}

.contexto-texto {
  font-size: 13px;
  color: #636363;
  font-weight: bold;
}

.nombre-organizacion-input {
  width: 416px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #939393;
  color: #a3a3a3;
  font-size: 14px;
}

.input-contexto {
  width: 419px;
  height: 86px;
  border-radius: 4px;
  border: 1px solid #939393;
  color: #a3a3a3;
  font-size: 14px;
}

.div-inputs {
  position: absolute;
  top: 190px;
  left: 1000px;
}

.div-ajuste-chatbot {
  width: 431px;
}

.div-ajuste-chatbot2 {
  width: 520px;
}

.listo-pagar-boton {
  width: 114px;
  height: 25px;
  border-radius: 6px;
  background-color: #e3f4ff;
  border: 1px solid #2e77bb;
  color: #003a70;
  cursor: pointer;
  font-size: 12px;
}

.numero-equivocado-boton {
  width: 139px;
  height: 25px;
  border-radius: 6px;
  background-color: #fffee3;
  border: 1px solid #bb9c2e;
  color: #93750b;
  cursor: pointer;
  font-size: 12px;
}

.denegacion-pago-boton {
  width: 144px;
  height: 25px;
  border-radius: 6px;
  background-color: #ffe3e3;
  border: 1px solid #9e2d1d;
  color: #9e2d1d;
  cursor: pointer;
  font-size: 12px;
}

.boton-mas {
  color: #a0aec0;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  border: none;
  font-size: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.boton-activo {
  width: 103px;
  height: 38px;
  background-color: white;
  border: 1px solid #939393;
  color: black;
  border-radius: 15px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.boton-desactivado {
  width: 103px;
  height: 38px;
  background-color: #c0c0c0;
  border: 1px solid #939393;
  color: #7f7f7f;
  border-radius: 15px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.conocimiento-div1 {
  width: 45%;
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.adjuntar-texto-bold {
  font-size: 19px;
  font-weight: bold;
}

.adjuntar-texto-normal {
  font-size: 16px;
  font-weight: bold;
  color: #636363;
}

.Probar-bot {
  border-radius: 23.15px;
  width: 180px;
  height: 36px;
  cursor: pointer;
  background-color: #2e77bb;
  color: white;
  border: none;
  pointer-events: auto;
  font-weight: bold;
  font-size: 15px;
}

.nombre-etiqueta {
  font-size: 27px;
  position:static;
  margin-left: 50px;
  margin-top: 10px;
}

.WHATSAPP-etiqueta {
  font-size: 15px;
  font-weight: bold;
  color: #1fa727;
  position: static;
  margin-left: 50px;
  margin-bottom: -10px;
}

.dragdrop {
  width: 33vw;
  height: 46.52vh;
  border: 2px dotted #c9c9c9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 30px;
}

.cs-message--incoming .cs-message__content {
  background-color: #252b3f !important;
  color: white !important;
}

.cs-message--outgoing .cs-message__content {
  background-color: #3a5c7b !important;
  color: white !important;
}

.cs-message-input__content-editor-wrapper {
  background-color: white !important;
}

.cs-message-input__content-editor {
  background-color: white !important;
}

.cs-button--send {
  color: black !important;
}

.cs-message-list {
  background: radial-gradient(
    circle,
    rgba(83, 168, 113, 0.8),
    rgba(46, 119, 187, 0.8)
  ) !important;
  padding-top: 10px;
  min-height: calc(89%) !important;
  max-height: 100px !important;
}

.cs-main-container {
  border: none !important;
}

.btn-icon {
  cursor: pointer;
}

.cs-main-container {
  height: 100% ;
  width: 116.5%;
}

.porfavor-ingresa-tus {
  font-size: var(--font-size-3xs);
  opacity: 0.8;
}

pre {
  font-family: var(--font-manrope);
}

.bienvenido {
  font-size: 30px;
  font-family: var(--font-inter);
}

.botn {
  box-sizing: border-box;
  color: var(--color-white);
  font-weight: 800;
  mix-blend-mode: normal;
  width: 100%;
}

.image-5-icon {
  width: 103px;
  height: 28px;
  object-fit: cover;
}

.vector-icon {
  width: 20.6px;
  height: 19.8px;
}

.vector-icon1,
.vector-icon2 {
  width: 20.8px;
  height: 20px;
}

.layer-1 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  opacity: 0.5;
}

.olvidaste-tu-contrasea {
  font-size: var(--font-size-3xs);
  opacity: 0.8;
  text-decoration: underline;
  color: var(--color-gray);
}

.main-1-1 {
  width: 495px;
  height: 523px;
}

.frame1 {
  align-self: stretch;
  border-radius: var(--br-11xl);
  background-color: var(--color-white);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  height: 550px;
  overflow: hidden;
  flex-shrink: 0;
}

.aviso-de-privacidad {
  font-size: 13px;
  text-decoration: underline;
  color: var(--color-white);
  opacity: 0.8;
  cursor: pointer;
}

.frame,
.login {
  overflow: hidden;
}

.frame {
  width: 892px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  font-size: var(--font-size-mini);
  color: var(--color-black);
  font-family: var(--font-manrope);
}

.login {
  background: linear-gradient(180deg, #54acde, #4d77b1);
  width: 100%;
  height: 100vh;
  font-size: var(--font-size-xs);
  color: #4d77b1;
  font-family: var(--font-bebas-neue);
}

.inputtype {
  width: 128px;
  height: 32px;
  border: 1px solid #939393;
  border-radius: 6px;
}

.selectpersonalidad {
  border-radius: 8px;
  width: 136px;
  height: 32px;
  border: 1px solid #939393;
  color: black;
}

.input-text-ajuste {
  width: 419px;
  height: 169px;
  border: 1px solid #939393;
  border-radius: 6px;
}

.subir-foto {
  font-size: 14px;
  color: #2e77bb;
  font: Helvetica;
  text-decoration: underline;
}

.foto-usuario-modal {
  width: 114px;
  height: 114px;
}

.nombre-chatbot-modal {
  width: 200px;
  height: 32px;
  border-radius: 4px;
  color: #c9c9c9;
  background-color: white;
  border: 1px solid #c9c9c9;
  font-size: 14px;
  padding-left: 5px;
}

.titulos-modal {
  height: 17px;
  width: 77px;
  font-weight: bold;
  color: #636363;
  font-size: 14px;
}

.id-modal {
  height: 17px;
  width: 130px;
  color: #636363;
  font-size: 14px;
  font: var(--font-manrope);
}

.nombre-personalidad-modal {
  width: 200px;
  height: 32px;
  border-radius: 4px;
  color: black;
  background-color: white;
  border: 1px solid #c9c9c9;
  font-size: 14px;
}

.mapfiles {
  font-size: 14px;
}

.btn-enabled {
  background-color: #def1df;
  color: #27852c;
}

.btn-disabled {
  background-color: #ffe3e3;
  color: #9e2d1d;
}

.graph {
  width: 100%;
  height: 90%;
  background-color: white;
  margin-top: 10px;
}

.heat-graph {
  width: 90%;
  height: 90%;
  background-color: white;
  margin-top: 10px;
}

.conversacionvacia {
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  color: #929292;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filtrochat {
  font-size: 10px;
}

.filtro-checkbox .chakra-checkbox__control {
  border: 1px solid #bbb;
}

.filtro-checkbox .chakra-checkbox__control[data-checked] {
  background-color: #2e77bb;
  border: 1px solid #bbb;
}

.div-botones-overview {
  background-color: #f4f4f5;
  color: black;
  border-radius: 10px;
  height: 7.8vh;
  width: 70.22vw;
  display: flex;
  align-items: center;
  justify-content: left;
  cursor: pointer;
}

.boton-overview-seleccionado {
  width: 22.33vw;
  height: 7.8vh;
  background-color: #e0f0ff;
  color: #4177b6;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.boton-overview {
  width: 22.33vw;
  height: 7.8vh;
  color: #d1d1d3;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.titulo-overview {
  font-size: 30px;
  font-weight: bold;
}

.div-overview {
  margin-top: 20px;
  margin-left: 57px;
  justify-content: flex-start;
}

.overview-contenedor-tarjeta {
  width: 100vw;
  height: 15vh;
  border: 1px solid #636363;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(99, 99, 99, 0.25);
  text-align: left;
}

.texto-overview-aumento {
  font-size: 12px;
  color: #636363;
}

.texto-overview-message {
  font-size: 20px;
  color: black;
  font-weight: bold;
}

.texto-overview-titulo {
  font-size: 12px;
  color: black;
  width: 160px;
  font-weight: bold;
}

.div-grafica-overview {
  width: 42vw;
  height: 57vh;
  border: 1px solid #636363;
  box-shadow: 0 2px 4px 0 rgba(99, 99, 99, 0.25);
  border-radius: 10px;
}

.div-grafica-temporales {
  width: 34vw;
  height: 58vh;
  border: 1px solid #636363;
  box-shadow: 0 2px 4px 0 rgba(99, 99, 99, 0.25);
  border-radius: 10px;
}

.div-ventas-overview {
  width: 27vw;
  height: 57vh;
  border: 1px solid #636363;
  box-shadow: 0 2px 4px 0 rgba(99, 99, 99, 0.25);
  border-radius: 10px;
  padding: 20px;
}

.div-ventas-titulo {
  font-size: 20px;
  color: #000000;
}

.div-ventas-texto {
  font-size: 12px;
  color: #636363;
}

.foto-usuario-overview {
  height: 39px;
  width: 39px;
  border: 100px;
}

.div-persona-venta {
  width: 49vw;
  height: 5vw;
}

.div-venta-tarjetas {
  width: 70.2vw;
}

.persona-overview-nombre {
  font-size: 16px;
}

.persona-overview-telefono {
  font-size: 12px;
  color: #636363;
}

.boton-download {
  background-color: #e0f0ff;
  width: 8.75vw;
  height: 5.4vh;
  font-size: 17px;
  color: #4177b6;
  border-radius: 5px;
  font-weight: bold;
}

.boton-calendario {
  background-color: white;
  width: 17vw;
  height: 5.4vh;
  font-size: 12px;
  color: #000000;
  border: 1px solid #636363;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}

.input-fecha {
  width: 90px;
  height: 37px;
  border-radius: 2px;
  border: 1px solid #636363;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.popup-calendario {
  width: 502px;
  height: 623px;
}

.div-fechas {
  font-size: 12.5px;
  width: 90px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.div-fechas-calendario {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*Calendario */
.calendar {
  padding: 20px;
  width: 500px;
}

.calendar ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  text-align: center;
}

.calendar li {
  height: 50px;
  width: calc(100% / 7);
  font-size: 12px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.day-calendar {
  background-color: white;
  width: 50px;
  height: 50px;
  border: #d5d4df 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.selected-day {
  background-color: #4177b6;
  /* Cambia este color según tus preferencias */
  color: white;
  /* Cambia este color según tus preferencias */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

/*Calendario */
.foto-usuario-overview {
  width: 24px;
  height: 24px;
}

.boton-analitica {
  width: 16.6vw;
  height: 7.73vh;
  background-color: #f4f4f4;
  color: #636363;
  border-radius: 50px;
  font-weight: bold;
}

.boton-analitica-seleccionado {
  width: 16.6vw;
  height: 7.73vh;
  background-color: #e0f0ff;
  color: #4177b6;
  border-radius: 50px;
  font-weight: bold;
}

.div-nube-palabras {
  width: 41.93vw;
  height: 54vh;
  border: none;
  border-radius: 10px;
}

.div-alerta-graficas {
  width: 500px;
  height: 277px;
  border: 1px solid #636363;
  box-shadow: 0 2px 4px 0 rgba(99, 99, 99, 0.25);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imagen-navbar {
  margin-left: 10px;
  margin-right: 20px;
  width: 20px;
  height: 20px;
}

.imagen-navbar-d {
  padding: auto;
  width: 20x;
  height: 20px;
}

.imagen-navbar-campaign {
  margin-left: 10px;
  margin-right: 13px;
}

.temperatura-texto {
  font-size: 12px;
  color: #636363;
}

.div-persona-venta {
  width: 381px;
  height: 39px;
  display: flex;
  align-items: center;
  text-align: left;
}

/*Codigo barrita*/
.barra-fondo {
  height: 5px;
  background: #ccc;
  border-radius: 5px;
}

.barra-progreso {
  height: 100%;
  border-radius: 5px;
}

/*Codigo barrita*/
.porcentaje-progreso {
  margin-top: 8px;
}

.listo-pagar-texto {
  margin-top: -10px;
  font-size: 10px;
  margin-left: 11px;
  color: #1d8ece;
  background-color: #e5f2f6;
  width: 85px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.texto-info-contacto {
  text-align: start;
}

.inbox-input {
  border-radius: 10px;
  margin-left: 10px;
  border-color: 2px #000000 solid;
}

.inbox-input-n {
  border-radius: 10px;
  margin-left: -15px;
  border-color: 2px #000000 solid;
  margin-right: 29px;
}

.texto-calendario {
  width: 249px;
}

.dashboard-espaciado {
  margin-left: 60px;
}

.reporte-tarjeta {
  width: 363px;
  height: 70px;
  text-align: left;
  border-left: 1px solid #003a70;
  border-right: 1px solid #003a70;
}

.titulo-reporte-popup {
  font-size: 22px;
}

.texto-prop-reporte-popup {
  font-size: 15px;
  color: #636363;
}

.editar-chatbot {
  width: 7px;
  height: 20px;
}

.boton-chatbot {
  left:130px;
  position: absolute;
}

.boton-chatbot p {
  display: block;
  color: black;
  text-decoration: none;
  padding: 5px;
  cursor: pointer;
  font-size: 18px;
}
.boton-chatbot .content {
  display: none;
  background-color: white;
  position: absolute;
  min-width: 100px;
  box-shadow: 1px 1px 5px black;
  border-radius: 10px;
}

.boton-chatbot:hover .content {
  display: block;
  color: #0064c2;
}

.boton-chatbot p:hover {
  color: #0064c2;
}

/* word cloud*/
.wordcloud {
  display: flex;
  flex-direction: column;
  user-select: none;
}

.wordcloud svg {
  margin: 1rem 0;
  cursor: pointer;
}

.wordcloud label {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  margin-right: 8px;
}

.wordcloud textarea {
  min-height: 100px;
}

/**/
.tarjeta-reporte {
  width: 33.34vw;
  height: 26vh;
  color: black;
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
}

.texto-reporte {
  width: 100%;
}

.tarjeta-reporte-p {
  font-weight: bold;
  width: 11.06vw;
  padding: 10px;
  text-align: center;
}

.tarjeta-reporte-p-2 {
  font-weight: bold;
  width: 11.06vw;
  padding: 10px;
  border-left: #2e77bb 2px solid;
  border-right: #2e77bb 2px solid;
  text-align: center;
}

.boton-reporte-descagar {
  border-radius: 24px;
  width: 19vh;
  height: 5.5vh;
  color: white;
  background-color: #4177b6;
  margin-left: auto;
  font-weight: bold;
  margin-right: 20px;
  margin-bottom: 10px;
}

.titulo-reporte-texto {
  color: #2e77bb;
  font-size: 17px;
  margin-top: 10px;
  font-weight: bold;
}

.texto-chatbot {
  color: #636363;
  font-size: 15px;
}

.div-reporte {
  width: 70.2vw;
}

.imagen-nube {
  width: 100vw;
}

.mantenimiento-fondo {
  width: 100%;
  height: 100%;
}

.boton-bolita {
  width: 20px;
  height: 20px;
  background-color: #0064c2;
  border-radius: 100px;
}

.boton-bolita2 {
  width: 20px;
  height: 20px;
  background-color: #9e2d1d;
  border-radius: 100px;
}

.div-botones-login {
  width: 16.6vw;
  height: 6.19vh;
  border-radius: 10px;
  border-color: 1px solid #adadad;
}

.boton-login-seleccionado {
  width: 8.3vw;
  height: 6.19vh;
  border-radius: 10px;
  background-color: #2e77bb;
  color: white;
  border: none;
}

.boton-login {
  width: 8.3vw;
  height: 6.19vh;
  border-radius: 10px;
  background-color: none;
  color: black;
  border: none;
}

.input-login {
  border-radius: 0px;
  border-bottom: 1px solid black;
  color: #ababab;
  width: 18vw;
  margin-top: 20px;
}

.iniciar-sesion {
  background-color: #2e77bb;
  color: white;
  border-radius: 30px;
  width: 11vw;
  height: 6vh;
}
.div-conocimiento-espaciado {
  height: 10vh;
}
.div-dragespacio {
  height: 55.52vh;
}
.input-conocimiento {
  width: 33vw;
  height: 46.52vh;
  border: 2px dotted #c9c9c9;
  border-radius: 30px;
  padding: 10%;
  resize: none;
}

.cs-message__footer {
  color: black !important;
}
