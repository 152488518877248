@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap");

body {
  margin: 0;
  line-height: normal;
  font: var(--font-manrope);
}

:root {
  /* fonts */
  --font-manrope: Manrope;
  --font-inter: Inter;
  --font-bebas-neue: "Bebas Neue";

  /* font sizes */
  --font-size-3xs: 10px;
  --font-size-xs: 12px;
  --font-size-mini: 15px;
  --font-size-5xs: 8px;
  --font-size-6xs-7: 6.7px;
  --font-size-5xs-9: 7.9px;
  --font-size-xs-3: 11.3px;
  --font-size-4xl-2: 23.2px;
  --font-size-base-1: 16.1px;
  --font-size-lg: 18px;
  --font-size-smi: 13px;
  --font-size-sm: 14px;
  --font-size-xl-1: 20.1px;
  --font-size-lg-8: 18.8px;
  --font-size-11xl: 30px;
  --font-size-base-6: 15.6px;
  --font-size-sm-2: 13.2px;

  /* Colors */
  --color-white: #fff;
  --color-black: #000;
  --color-gray: #787878;
  --color-dimgray: #565656;
  --color-gray-100: #787878;
  --color-gray-200: #010101;
  --color-skyblue: #a6d3f2;
  --color-brown: #9e2d1d;
  --color-mediumseagreen: #53a871;
  --color-steelblue-100: #1d8ece;
  --color-darkgray: #ababab;
  --color-goldenrod: #febd59;
  --color-lightgray: #dad5d5;
  --color-deepskyblue-100: #54acde;
  --color-silver: #c9c9c9;
  --color-steelblue-200: #4d77b1;
  --color-steelblue-300: #4177b6;
  --color-steelblue-400: #2e77bb;
  --color-lightgray-100: #dad5d5;
  --color-lightgray-200: #d6d6d6;
  --color-gray-400: #a0aec0;
  --color-green-500: #38a169;
  --color-teal: #407c84;
  --color-deepskyblue-200: #4aa5d8;
  --color-darkgray-100: #ababab;
  --color-lightgray-300: #cdcbcb;
  --color-lightblue: #c7e1fa;

  /* Gaps */
  --gap-3xs: 10px;
  --gap-9xs-1: 3.1px;
  --gap-4xs-7: 8.7px;
  --gap-30xl: 49px;

  /* Paddings */
  --padding-mini: 15px;
  --padding-11xl: 30px;
  --padding-xs: 12px;
  --padding-base: 16px;
  --padding-4xl: 23px;
  --padding-10xs-2: 2.2px;
  --padding-mid: 17px;
  --padding-sm-9: 13.9px;
  --padding-xl: 20px;
  --padding-30xl: 49px;

  /* Border radiuses */
  --br-11xl: 30px;
  --br-9xs-4: 3.4px;
  --br-14xl-7: 33.7px;
  --br-xs-5: 11.5px;
  --br-9xs: 4px;
  --br-18xl-7: 37.7px;
  --br-9xs-4: 3.4px;
  --br-3xs: 10px;
  --br-xl: 20px;
  --br-3105xl-7: 3124.7px;
}